import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="active parent-active last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/veelgestelde-vragen-diarree/wat-is-diarree/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/veelgestelde-vragen-diarree/quest-ce-que-la-diarrhee/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="3ca25c7d3bbd9ab76adeebca1578855629a05bb3"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first">
                <a href="/fr/comment-agit-imodium/">Comment agit IMODIUM®?</a>
                <ul>
                  <li className="first last">
                    <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                      La substance active : lopéramide
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/">Médicaments IMODIUM®</a>
                <ul>
                  <li className="first">
                    <a href="/fr/medicaments-imodium/imodium-instant/">
                      IMODIUM® Instant
                    </a>
                  </li>
                  <li>
                    <a href="/fr/medicaments-imodium/imodium-duo/">
                      IMODIUM® Duo
                    </a>
                  </li>
                  <li>
                    <a href="/fr/medicaments-imodium/imodium-capsules/">
                      IMODIUM® Capsules
                    </a>
                  </li>
                  
                </ul>
              </li>
              <li>
                <a href="/fr/la-diarrhee/">En savoir plus sur la diarrhée</a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                      Conseils pour le traitement des symptomes de la diarrhée
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                      Causes de la diarrhée
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                      Stress et diarrhée
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                      Infection virale ou bactérienne
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/norovirus/">Norovirus</a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/la-diarrhee-pendant-les-regles/">
                      La diarrhée pendant les règles
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/la-diarrhee-chronique/">
                      La diarrhée chronique
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/consultez-votre-medecin/">
                      Consulter un médecin?
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                      Des habitudes alimentaires saines
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/fr/diarrhee-en-voyage/">Diarrhée en voyage</a>
                <ul>
                  <li className="first">
                    <a href="/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/">
                      Eviter la diarrhée du voyageur
                    </a>
                  </li>
                  <li>
                    <a href="/fr/diarrhee-en-voyage/traitement-de-la-diarrhee-du-voyageur/">
                      Traitement de la diarrhée du voyageur
                    </a>
                  </li>
                  <li>
                    <a href="/fr/diarrhee-en-voyage/pharmacie-de-voyage/">
                      Pharmacie de voyage
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/fr/diarrhee-chez-les-enfants/">
                  Diarrhée chez les enfants
                </a>
              </li>              
              <li>
                <a href="/fr/plan-du-site/">Plan du site</a>
              </li>
              <li>
                <a href="/fr/mentions-legales/">Mentions légales</a>
              </li>
              <li>
                <a href="/fr/respect-de-la-vie-privee/">
                  Respect de la vie privée
                </a>
              </li>
              <li>
                <a href="/fr/politique-en-matiere-de-cookies/">
                  Politique en matière de cookies
                </a>
              </li>
              <li>
                <a href="/fr/contact/">Contact</a>
              </li>
              <li>
                <a href="/fr/lexique/">Lexique</a>
              </li>
              <li className="active parent-active last">
                <a href="/fr/faq-questions-diarrhee/">FAQ</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Conseils en cas de diarrhée</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Conseils en cas de diarrhée"
                  />
                </div>
                <p>
                  Quand il s'agit du traitement des symptômes de la diarrhée, il
                  existe encore de nombreuses incertitudes. Lire quelques
                  conseils.
                </p>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils diarrhée
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Pharmacie de voyage</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_reisen.jpeg"
                    width="170"
                    height="102"
                    alt="Pharmacie de voyage"
                  />
                </div>
                <p>
                  Ce que doit contenir la&nbsp;
                  <strong>pharmacie de voyage</strong>? Trouvez ici toutes les
                  informations pratiques.
                </p>
                <a href="/fr/diarrhee-en-voyage/pharmacie-de-voyage/">
                  Plus d'info
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-93">
            <h1>Qu’est-ce que la diarrhée?</h1>
            <p>
              Lorsque votre système digestif fonctionne normalement, la
              nourriture et les liquides transitent de l'estomac dans l'intestin
              grêle, puis dans le côlon. L'intestin grêle et le côlon sont
              tapissés de cellules qui absorbent les nutriments et l'eau dont
              l'organisme a besoin. Le reste de la nourriture et de l'eau
              transitent alors dans l'organisme, sous la forme de selles.
            </p>
            <p>
              Lorsque ces cellules sont irritées, le mouvement habituellement
              lent et rythmé des intestins peut devenir hyperactif, transportant
              nourriture et liquides trop rapidement à travers le côlon, de
              sorte que de moindres quantités en sont absorbées par l'organisme.
              Ceci provoque des selles liquides - mieux connues sous le nom de
              diarrhée.
            </p>
            <p>
              Pour en savoir plus, consultez notre section{" "}
              <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                Causes de la diarrhée
              </a>
              .
            </p>
            <a className="button radius" href="/fr/faq-questions-diarrhee/">
              lisez encore plus de questions
            </a>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
